.lichess-font {
  font-family: 'Lichess';
  font-size: 15px;
}

.san-cell {
  cursor: pointer;
  line-height: 30px;
}

.san-cell:hover {
  background-color: #7dcbbc;
  line-height: 30px;
}

.san-cell-mistake {
  background-color: #D36446;

}

.san-cell-missed-gain {
  background-color: #F2B14F;
}

.san-cell-current {
  font-weight: bold;
  /*outline: 1px solid black;
  outline-offset: -1px;*/
}

.quizz-indication {
  height: 50px;
  line-height: 50px;
  width: 300px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: #1976d2;
}