.side-img {
  width: 30px;
}

.start-side-img {
  width: 80px;
  opacity: 1;
}

button:disabled>.start-side-img {
  opacity: 0.5;
}


.chapter-orientation-toggle {
  opacity: 1;
}

.chapter-orientation-toggle:disabled {
  opacity: 0.5;
}

.chessboard-container {
  position: relative;
}

.success-check-icon-container {
  z-index: 10;
  top: 0;
  position: absolute;

  background-color: rgb(0, 0, 0, 0);
  visibility: hidden;
  transition: background-color 0.3s ease-in-out;
}


.success-check-icon-container.visible {
  visibility: visible;
  background-color: rgb(0, 0, 0, 0.6);
}

.preparation-container {
  max-width: calc(100% - 30px);
}

@media screen and (min-width: 600px) {
  .preparation-container {
    max-width: 600px;
  }
}