.App {
  text-align: center;
  background-color: #f9f8f9;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.clickable {
  cursor: pointer;
  opacity: 0.6;
}

.cursorPointer {
  cursor: pointer;
}

.filter-on-click {
  position: relative;
}

.pie-filter-button {
  position: absolute;
  right: 10px;
  top: 30px;
  cursor: pointer;
}

.pie-filter-button:hover {
  color: #1565c0
}

.selected {
  opacity: 1 !important;
}

.app-container {
  background-color: #f9f8f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(51, 51, 51);
}

.openings {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.end-games {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.opening-container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  align-items: center;
  justify-content: center;
}

.end-games-container {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

.card-title {
  margin-block-start: 0;
}

.hoverGray:hover {
  background-color: rgb(51, 51, 51, 0.2);
}

.countryflag {
  display: block;
  bottom: 0;
  left: 0;
  font-size: 20px;
}

.stats-cell {
  width: 100;
  font-size: 12px;
}

.stats-cell>.elo {
  font-weight: bold;
}